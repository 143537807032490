.Btn__Donate {
    height: 40px;
    color: rgb(56, 97, 251) !important;
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.Btn__Donate .MuiButton-startIcon{
  color: rgb(56, 97, 251);
  animation: heart-beats 3s infinite;
}

@keyframes heart-beats {
  0%   { transform: scale(1);}
  7%  { transform: scale(1.2); }
  12%  { transform: scale(1.1); }
  20%  { transform: scale(1.3); }
  60%  { transform: scale(1); }
  100% { transform: scale(0.99); }
}
